
import * as aroraProviderRuntime$A9xhj7B6EKPoTIU_45PlHl9Q1_45kItVFPaTU3_45ieLiVj2I from 'D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-93ab5e21-a091-4846-b22f-f911a60ec7b3/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$A9xhj7B6EKPoTIU_45PlHl9Q1_45kItVFPaTU3_45ieLiVj2I, defaults: {} }
}
        